import { AlternativeType, SentenceType, StatusType } from "App.types";
import styles from "./DialogTaskOptions.module.scss";
import React, { FC, useEffect, useMemo, useState } from "react";
import { getCompletedSlate, textIncludesAnswer } from "App.helpers";
import { SkipTags, VOICES } from "App.constants";
import StoryDialogSentence from "Components/StoryDialogSentence";
import RecordButton from "Components/RecordButton";
import AnimateFade from "Components/AnimateFade";
import { Flex } from "antd";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import { last } from "lodash";

const DialogTaskTranslate: FC<{
  onClickRecording: () => any;
  audioStatus: StatusType;
  sentence: SentenceType;
  onSuccess: Function;
  storyId?: number;
  textFromSpeech: string;
  isSpeaking: boolean;
  audio: HTMLAudioElement;
}> = ({
  textFromSpeech,
  onClickRecording,
  sentence: { tags, alternatives, text, isLeft },
  sentence,
  onSuccess,
  storyId,
  audioStatus,
  isSpeaking,
  audio,
}) => {
  const [taskStatus, setTaskStatus] = useState<StatusType>(StatusType.Empty);
  const [answer, setAnswer] = useState<Partial<AlternativeType>>();
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);

  // const audio = useMemo(() => new Audio(getSentenceAudioUrl(id, text, voice || VOICES[10].value)), [id, text, voice]);

  const completedSlate = useMemo(() => getCompletedSlate(tags || [], text), [text, tags]);
  const filteredRightTags = useMemo(() => completedSlate.filter((el) => el.word && !SkipTags.includes(el.word)), [completedSlate]);

  useSpeechCheck({ status: taskStatus, sentence, textFromSpeech, setCompletedTagIdx, completedTagIdx, filteredRightTags });

  useEffect(() => {
    if (taskStatus !== StatusType.Completed && completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      setTaskStatus(StatusType.Completed);
      onSuccess(sentence.id);
    }
  }, [completedTagIdx, filteredRightTags, onSuccess, sentence.id, taskStatus]);

  useEffect(() => {
    if (taskStatus !== StatusType.Completed) {
      if (textIncludesAnswer(textFromSpeech, text)) {
        setTaskStatus(StatusType.Completed);
        return onSuccess();
      }

      const alt = alternatives.find((alt) => textIncludesAnswer(textFromSpeech, alt.text));
      if (alt) {
        setAnswer(alt);
        setTaskStatus(StatusType.Completed);
        return onSuccess();
      }
    }
  }, [textFromSpeech, text, alternatives, taskStatus, onSuccess]);

  return (
    <div className={styles.dialogTaskOptions}>
      <Flex gap={5} align={"center"} style={{ flexDirection: isLeft ? "row-reverse" : undefined }}>
        <AnimateFade hide={taskStatus === StatusType.Completed}>
          <RecordButton
            onClick={() => {
              setCompletedTagIdx(-1);
              onClickRecording();
            }}
            isRecording={audioStatus === StatusType.IsRecording}
            loading={audioStatus === StatusType.Loading}
            isSpeaking={isSpeaking}
          />
        </AnimateFade>{" "}
        <StoryDialogSentence
          completedTagIdx={completedTagIdx}
          storyId={storyId}
          audio={audio}
          text={answer?.text}
          tags={answer?.tags}
          sentence={sentence}
          active={taskStatus !== StatusType.Completed}
          voice={sentence.voice ?? VOICES[sentence.isLeft ? 0 : 1].value}
          showTranslate={taskStatus !== StatusType.Completed}
        />
      </Flex>
    </div>
  );
};

export default DialogTaskTranslate;
