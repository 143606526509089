import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { Button, Flex, notification } from "antd";
import API from "Api";
import { isEqualText, withCustomLogic } from "App.helpers";
import { CloseOutlined } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { successMessage } from "App.constants";
import { capitalize, isNumber, shuffle, uniq } from "lodash";
import { isMobile } from "react-device-detect";
import { animateScroll } from "react-scroll";
import TaskPanel from "Components/TaskPanel";

const initialValue: any[] = [{ children: [{ text: "" }] }];

const ReverseSelectTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, translate, reverseGroup, alternatives, reverseIdx, markers, storyId },
  lesson,
  onTaskComplete,
  onNext,
  children,
  audio,
  setDictOpened,
  alignCenter = false,
  showGrammar,
  setTry,
  noText,
  transcripts,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const words = useMemo(() => {
    const alterWords = alternatives.filter((alt) => !alt.media && alt.translate).map((alt) => alt.translate);
    if (reverseGroup?.title === "from sentences" && alterWords.length)
      return uniq(shuffle(isNumber(reverseIdx) ? alterWords : [translate, ...alterWords])).filter((el) => el);

    const filtered = uniq(shuffle(reverseGroup?.words.filter((w) => w !== translate))).filter((el) => el);
    return [translate, ...filtered.slice(0, (lesson.selectLimit ?? 6) - 1)].sort();
  }, [alternatives, reverseGroup?.title, reverseGroup?.words, reverseIdx, translate, lesson.selectLimit]);

  const setEditorState = useCallback(
    (completed = false) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      if (completed && !isNumber(sentence.reverseIdx)) {
        return Transforms.insertNodes(editor, [{ children: [{ text: translate, answer: text }] }]);
      }

      if (isNumber(sentence.reverseIdx)) {
        const words = translate.split(" ");

        Transforms.insertNodes(editor, [
          {
            children: words.map((word, idx) =>
              idx === sentence.reverseIdx
                ? { text: `${idx === sentence.reverseIdx && !completed ? " " : word} `, isMasked: true, answer: word }
                : { text: `${idx ? " " : ""}${word}${idx + 1 === sentence.reverseIdx ? " " : ""}` },
            ),
          },
        ]);
      } else {
        Transforms.insertNodes(editor, [{ children: [{ text: "", answer: text }] }]);
      }
    },
    [editor, sentence.reverseIdx, text, translate],
  );

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setEditorState();
  }, [sentence.id, editor, setEditorState]);

  // audio transcript
  useAudioTranscript({ audio, sentence, setActiveLeaf, transcripts });

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      if (showMessage) {
        notifyApi.success(successMessage);
      }

      setEditorState(true);

      onTaskComplete(id);
    },
    [setEditorState, onTaskComplete, id, notifyApi],
  );

  const onCheck = useCallback(
    async (textAnswer: string = "", isHintClicked: boolean = false): Promise<StatusType> => {
      isMobile && animateScroll.scrollToTop();
      notifyApi.destroy();

      const userAnswerText = textAnswer || editor.children[0]?.children?.map((el: CustomText) => el.text).join(" ") || "";

      if (isEqualText(userAnswerText, translate) || markers.includes("anySelect")) {
        onComplete(false);
        return StatusType.Completed;
      }

      setTry();
      setStatus(StatusType.Error);

      let maskedIdx = editor.children[0]?.children.findIndex((t: any) => t.isMasked);
      if (maskedIdx === -1) maskedIdx = 0;
      const userAnswer = editor.children[0]?.children[maskedIdx]?.text;
      if (!userAnswer) {
        Transforms.setNodes(editor, { status: "empty" }, { at: [0, maskedIdx] });
      } else {
        Transforms.setNodes(editor, { status: "wrong" }, { at: [0, maskedIdx] });
      }

      if (userAnswerText.trim() && !isHintClicked) {
        API.event.save({ sentence, text: userAnswerText, type: "mistake", task: TaskType.ReverseSelect, lesson: lesson });
      }
      return StatusType.Error;
    },
    [editor, lesson, notifyApi, onComplete, sentence, setTry, translate, markers],
  );

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf showMasked showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)} {...props} />
    ),
    [status],
  );

  const onHintAnswer = useCallback(async () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join(" ");
    API.event.save({ text, type: "hint", task: TaskType.ReverseSelect, lesson: { id: lesson.id }, sentence });

    const result = await onCheck(text, true);
    if (result !== StatusType.Completed) {
      setTry(true);
      let maskedIdx = editor.children[0]?.children.findIndex((t: any) => t.isMasked);
      if (maskedIdx === -1) maskedIdx = 0;

      const { answer } = editor.children[0]?.children[maskedIdx];
      Transforms.insertText(editor, answer, { at: [0, maskedIdx] });
      Transforms.setNodes(editor, { status: "success" }, { at: [0, maskedIdx] });

      onComplete(false);
    }
  }, [editor, lesson.id, onCheck, sentence, setTry, onComplete]);

  // usePressEnter({ status, onNext, onCheck });

  const onReset = () => {
    setEditorState();
  };

  const onWordGroupClick = (t?: string) => (e: any) => {
    setSelectedAnswer(t);
    setStatus(StatusType.Editing);
    const idx = editor.children[0].children.findIndex((el: CustomText) => el.isMasked);
    const word = idx === 0 ? capitalize(e.key || t) : e.key || t;
    Transforms.insertText(editor, word, { at: [0, idx === -1 ? 0 : idx] });
    onCheck();
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            {initialValue && (
              <form spellCheck="false">
                <Slate editor={editor} initialValue={initialValue}>
                  <Editable className={styles.textArea} readOnly={true} renderLeaf={renderLeaf} />
                </Slate>
              </form>
            )}
            {status === "completed" ? (
              <span />
            ) : (
              // <Button
              //   size={"small"}
              //   type={"text"}
              //   className={styles.btn__clear}
              //   icon={<SoundOutlined style={{ fontSize: 22 }} />}
              //   onClick={() => play?.() || audio?.play()}
              // />
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>
          {((noText && status === StatusType.Completed) || !noText) && <div className={styles.translate}>{text}</div>}
        </div>

        <div className={styles.bottom}>
          <Flex gap={10} wrap={"wrap"} align={"center"} className={styles.select}>
            {words?.map((t) => (
              <span
                key={t}
                className={cx(styles.btnSelect, {
                  [styles.btn_disabled]: status === StatusType.Completed,
                  [styles.btn_red]: t === selectedAnswer && status === StatusType.Error,
                  [styles.btn_green]: status === StatusType.Completed && t === selectedAnswer,
                  [styles.btn_bold]: status === StatusType.Completed && t === selectedAnswer && markers.includes("anySelect"),
                })}
                onClick={onWordGroupClick(t)}
              >
                {t}
              </span>
            ))}
          </Flex>
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.ReverseSelect}
        sentId={id}
        storyId={storyId}
        onCheck={onCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        audio={audio}
        showGrammar={showGrammar}
        onHint={onHintAnswer}
      ></TaskPanel>

      {contextHolder}
    </div>
  );
};

export default ReverseSelectTask;
