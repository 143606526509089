import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { Button } from "antd";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { BookOutlined, CheckOutlined } from "@ant-design/icons";
import SentenceLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import TaskPanel from "Components/TaskPanel";
import { TaskType } from "App.types";

const initialValue = [{ children: [{ text: "" }] }];

const OneButtonTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives, storyId },
  onTaskComplete,
  onNext,
  children,
  audio,
  alignCenter = false,
  setDictOpened,
  transcripts,
  showGrammar,
  lesson,
}) => {
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    text && Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [tags, editor, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const renderLeaf = useCallback(
    (props: any) => <SentenceLeaf showTranslateTooltip underlined={props.leaf.audioIdx === (activeLeaf ?? -1)} showErrors {...props} />,
    [activeLeaf],
  );

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          {text && (
            <div className={styles.slate_wrapper}>
              <form spellCheck="false">
                <Slate editor={editor} initialValue={initialValue}>
                  <Editable
                    className={styles.textArea}
                    readOnly={true}
                    renderLeaf={renderLeaf}
                    //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                  />
                </Slate>
              </form>
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<BookOutlined />}
                onClick={() => setDictOpened(true)}
              />
            </div>
          )}
          {translate && <div className={styles.translate}>{translate}</div>}
        </div>
      </div>

      <TaskPanel lessonId={lesson.id} task={TaskType.OneButton} showGrammar={showGrammar} sentId={id} storyId={storyId}>
        <Button
          icon={<CheckOutlined />}
          className={styles.btn_next}
          type={"primary"}
          shape={"round"}
          onClick={() => {
            onTaskComplete();
            onNext();
          }}
        >
          {alternatives[0]?.text || "далее"}
        </Button>
      </TaskPanel>
    </div>
  );
};

export default OneButtonTask;
