import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { Button, Flex } from "antd";
import API from "Api";
import { withCustomLogic } from "App.helpers";
import { CloseOutlined } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { capitalize } from "lodash";
import TaskPanel from "Components/TaskPanel";

const initialValue: any[] = [{ children: [{ text: "" }] }];

const FeedbackSelectTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, translate, alternatives, description, storyId },
  lesson,
  onTaskComplete,
  onNext,
  children,
  alignCenter = false,
  noTranslate,
  noText,
  showGrammar,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];
    Transforms.insertNodes(editor, [{ children: [{ text: "", answer: text }] }]);
  }, [editor, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    onTaskComplete(id);
  }, [onTaskComplete, id]);

  const onNextClick = useCallback(() => {
    API.feedback
      .save({
        answer: selectedAnswer ?? "",
        question: text || translate || description || "",
        lessonId: lesson.id,
        type: "taskFeedback",
        task: "select",
        sentenceId: id,
      })
      .then(onComplete)
      .then(() => onNext());
  }, [selectedAnswer, text, translate, description, lesson.id, id, onComplete, onNext]);

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        showMasked
        hideMasked={status === StatusType.Completed}
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onReset = () => {
    setInitialState();
  };

  const onWordGroupClick = (t: string) => (e: any) => {
    setSelectedAnswer(t);
    setStatus(StatusType.Completed);
    const idx = editor.children[0].children.findIndex((el: CustomText) => el.isMasked);
    const word = idx === 0 && capitalize(text) === text ? capitalize(e.key || t) : e.key || t;
    Transforms.insertText(editor, word, { at: [0, idx === -1 ? 0 : idx] });
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          {!noText && (
            <div className={styles.slate_wrapper}>
              {initialValue && (
                <form spellCheck="false">
                  <Slate editor={editor} initialValue={initialValue}>
                    <Editable className={styles.textArea} readOnly={true} renderLeaf={renderLeaf} />
                  </Slate>
                </form>
              )}
              {status === "completed" ? (
                <div></div>
              ) : (
                <Button
                  size={"small"}
                  type={"text"}
                  className={styles.btn__clear}
                  icon={<CloseOutlined style={{ fontSize: 12 }} />}
                  onClick={onReset}
                />
              )}
            </div>
          )}
          {!noTranslate && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          <Flex gap={10} wrap={"wrap"} align={"center"} className={styles.select}>
            {alternatives?.map((alt) => (
              <span className={cx(styles.btnSelect, {})} key={alt.text} onClick={onWordGroupClick(alt.text)}>
                {alt.text}
              </span>
            ))}
          </Flex>
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.FeedbackSelect}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        isCompleted
        onNext={onNextClick}
      ></TaskPanel>
    </div>
  );
};

export default FeedbackSelectTask;
