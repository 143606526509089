import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { Button } from "antd";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { BookOutlined, BorderOutlined, SoundFilled } from "@ant-design/icons";
import SentenceLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import { usePressEnter } from "./Helpers/usePressEnter";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { delay } from "lodash";
import TaskPanel from "Components/TaskPanel";

const initialValue = [{ children: [{ text: "" }] }];

const ListenTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, storyId },
  activeType,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  alignCenter = false,
  setDictOpened,
  autoPlay,
  transcripts,
  showGrammar,
  lesson,
  noTranslate,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [soundPlayed, setSoundPlayed] = useState(false);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    text && Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [tags, editor, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setSoundPlayed(false);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  useEffect(() => {
    setAudioStatus(StatusType.Empty);

    const onEnded = () => {
      setAudioStatus(StatusType.Empty);

      setStatus((prevStatus) => {
        if (prevStatus === StatusType.Completed) {
          return prevStatus;
        } else {
          onTaskComplete(sentence.id);
          return StatusType.Completed;
        }
      });
    };

    if (status === StatusType.Completed || !audio) {
      // audio?.removeEventListener("ended", onEnded);
      // audio?.removeEventListener("pause", onEnded);
    } else {
      audio?.addEventListener("ended", onEnded);
      audio?.addEventListener("pause", onEnded);
    }
  }, [audio, status, onTaskComplete, sentence.id]);

  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    onTaskComplete(id);
  }, [onTaskComplete, id]);

  const renderLeaf = useCallback(
    (props: any) => <SentenceLeaf showTranslateTooltip underlined={props.leaf.audioIdx === (activeLeaf ?? -1)} showErrors {...props} />,
    [activeLeaf],
  );

  const onPlay = useCallback(() => {
    if (audioStatus === StatusType.isPlaying && audio) {
      audio.pause();
      audio.currentTime = 0;
      setAudioStatus(StatusType.Empty);
    } else {
      setAudioStatus(StatusType.isPlaying);
      play?.().then(onComplete) || audio?.play();
      setSoundPlayed(true);
    }
  }, [audio, audioStatus, onComplete, play]);

  usePressEnter({ status, onNext });

  useEffect(() => {
    if (!soundPlayed && audio && autoPlay) {
      if (audio.readyState === 4) {
        delay(() => onPlay(), 1000);
      } else {
        audio.oncanplaythrough = () => onPlay();
      }
    }
  }, [audio, autoPlay, onPlay, soundPlayed]);

  // console.log(editor.children[0]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed" || ["listen", "read", "select"].includes(activeType)}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
            <Button
              size={"small"}
              type={"text"}
              className={styles.btn__clear}
              icon={<BookOutlined />}
              onClick={() => setDictOpened(true)}
            />
          </div>
          {(!noTranslate || status === StatusType.Completed) && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          <Button
            disabled={!soundPlayed && autoPlay}
            type={status !== StatusType.Completed ? "primary" : undefined}
            icon={audioStatus === StatusType.isPlaying ? <BorderOutlined /> : <SoundFilled />}
            onClick={onPlay}
          />
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.Listen}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        audio={audio}
      ></TaskPanel>
    </div>
  );
};

export default ListenTask;
