import { Button, Collapse, Form, Input, Rate, Result, Space } from "antd";
import API from "Api";
import React, { FC, useEffect, useMemo } from "react";
import { CrownTwoTone } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { SentenceType, TagType, TaskType } from "App.types";
import { POS_TAGS, SkipTags } from "App.constants";
import { flatten, uniqBy } from "lodash";
import CountUp from "react-countup";
import styles from "./CompleteWindow.module.scss";

const CompleteWindow: FC<{
  sentences?: SentenceType[];
  onRepeat?: () => any;
  lessonId: number;
  course?: string;
}> = ({ lessonId, course, onRepeat, sentences = [] }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const queryClient = useQueryClient();

  const onFinish = (values: { feedback: string; rate: number }) => {
    if (values.feedback?.trim() || values.rate) {
      API.feedback.save({ lessonId, type: "lessonFeedback", question: values.feedback, rate: values.rate });
    }

    queryClient.invalidateQueries({ queryKey: ["wordCount"] });
    state ? navigate(-1) : navigate(course ? `/course/${course}` : "/");
  };

  const allSentences = useMemo<[SentenceType & { parentTasks: string[] }]>(
    () =>
      // @ts-ignore
      [
        ...sentences,
        ...flatten(sentences.map((sent) => sent.linkedStory?.sentences.map((s) => ({ ...s, parentTasks: sent.tasks })))).filter((s) => s),
      ],
    [sentences],
  );

  const activeTags = useMemo(() => {
    let result: TagType[] = [];

    allSentences.forEach((s) => {
      if (
        s.tasks.some((t: TaskType) => [TaskType.VoiceTranslate, TaskType.Translate].includes(t)) ||
        (s.parentTasks?.[0] === TaskType.Conversation && s.tasks.length === 0)
      ) {
        result = [...result, ...s.tags.filter((t: any) => !SkipTags.includes(t.word))];
      } else if (
        s.tasks.some(
          (t: TaskType) => [TaskType.VoiceTranslate, TaskType.Translate].includes(t) || s.parentTasks?.[0] === TaskType.StoryMasked,
        )
      ) {
        result = [...result, ...s.tags.filter((t: TagType) => t.isMasked)];
      }
    });

    return result;
  }, [allSentences]);

  const uniqWords = useMemo(
    () =>
      uniqBy(
        // @ts-ignore
        activeTags.map((t) => ({ word: t.lemma, pos: POS_TAGS[t.pos] })),
        (w) => `${w.word}${w.pos}`,
      ),
    [activeTags],
  );

  useEffect(() => {
    if (uniqWords.length) {
      API.progress.saveVocabularyProgress(uniqWords);
    }
  }, [uniqWords]);

  return (
    <Form onFinish={onFinish}>
      <Result
        className={styles.completeWindow}
        status="success"
        title="Задание выполнено!"
        subTitle={
          <>
            {!!uniqWords.length && (
              <div style={{ fontSize: 48 }}>
                <CountUp end={uniqWords.length} /> <CrownTwoTone className={styles.crown} />
              </div>
            )}
            Оцените задание:
            <Form.Item name={"rate"}>
              <Rate allowHalf style={{ fontSize: 32 }} />
            </Form.Item>
            <Collapse
              size={"small"}
              items={[
                {
                  label: "Оставить отзыв",
                  children: (
                    <Form.Item name={"feedback"}>
                      <Input.TextArea />
                    </Form.Item>
                  ),
                },
              ]}
            />
          </>
        }
        extra={
          <Space direction={"vertical"}>
            <Button htmlType={"submit"} type="primary" key="console">
              Завершить
            </Button>
            {onRepeat && (
              <Button key="buy" onClick={onRepeat}>
                Пройти еще раз
              </Button>
            )}
          </Space>
        }
      />
    </Form>
  );
};

export default CompleteWindow;
