import { useCallback } from "react";
import { AlternativeType, CustomText, LessonType, StatusType, TagType, TaskType } from "App.types";
import { isEqualEnglishWords, isEqualText } from "App.helpers";
import { Editor, Transforms } from "slate";
import API from "Api";
import { EmptySpaceTags, SkipTags, TagsToMerge } from "App.constants";
import { last } from "lodash";
import { NotificationInstance } from "antd/es/notification/interface";

type Props = {
  tags: TagType[];
  editor: any;
  onComplete: Function;
  text: string;
  alternatives: AlternativeType[];
  activeType: TaskType;
  setStatus?: Function;
  notifyApi?: NotificationInstance;
  id: number;
  lesson: LessonType;
};

export const useOnCheck = ({ editor, onComplete, text, notifyApi, alternatives, activeType, setStatus, id, tags, lesson }: Props) => {
  return useCallback(
    async (textAnswer: string = "", isHintClicked: boolean = false) => {
      const userAnswerText =
        textAnswer ||
        editor.children[0]?.children
          ?.map((el: CustomText) => el.text)
          .join("")
          .trim();

      console.log(userAnswerText);

      notifyApi?.destroy();

      if (!userAnswerText && !isHintClicked) return notifyApi?.info({ message: "выполни задание!" });

      if (isEqualText(userAnswerText, text)) {
        return onComplete();
      }

      if (alternatives.some((alt) => isEqualText(userAnswerText, alt.text))) {
        notifyApi?.success({ message: userAnswerText, description: "допустимый вариант перевода!" });
        return onComplete(false);
      }

      const taggedAnswer = userAnswerText ? await API.lemma.get(userAnswerText.replace("’", "'")) : [];
      //
      // if (alternatives.some((alternative) => compareTextByTags(alternative.tags, taggedAnswer))) {
      //   notifyApi.success({ message: userAnswerText, description: "допустимый вариант перевода!" });
      //   return onComplete(false);
      // }
      const spellResults =
        userAnswerText && !lesson.checkTypos
          ? await API.grammar.spellCheck(lesson.spellerHint ? `${lesson.spellerHint} ${userAnswerText}` : userAnswerText)
          : [];

      const tagsWithIdx = tags.filter((el) => !SkipTags.includes(el.word)).map((el, idx) => ({ ...el, idx }));
      const mutableRightTags: any[] = [...tagsWithIdx];

      // strict check
      let userAnswerNodes: CustomText[] = taggedAnswer
        .filter((el) => !SkipTags.includes(el.word))
        .map((userAnswerTag, idx) => {
          if (!tagsWithIdx[idx]) return userAnswerTag;
          // check word as is
          if (isEqualEnglishWords(userAnswerTag.word, tagsWithIdx[idx].word)) {
            const rightTag = { ...mutableRightTags[idx] };
            mutableRightTags[idx] = undefined;
            return { ...rightTag, status: "success", text: userAnswerTag.word };
            // check lemma
          } else if (isEqualEnglishWords(userAnswerTag.lemma, tagsWithIdx[idx].lemma)) {
            const rightTag = { ...mutableRightTags[idx] };
            mutableRightTags[idx] = undefined;
            return { ...rightTag, status: "lemma", text: userAnswerTag.word };
            // check article
          } else if (["a", "an", "the"].includes(userAnswerTag.word) && ["a", "an", "the"].includes(tagsWithIdx[idx].word)) {
            const rightTag = { ...mutableRightTags[idx] };
            mutableRightTags[idx] = undefined;
            return { ...rightTag, status: "article", text: userAnswerTag.word };
            // check typo
          } else {
            const typoElement = spellResults.find((el) => isEqualText(el.word, userAnswerTag.word));

            if (
              typoElement?.s.some(
                (word) => isEqualEnglishWords(word, tagsWithIdx[idx].word) || isEqualEnglishWords(word, tagsWithIdx[idx].lemma),
              )
            ) {
              const rightTag = { ...mutableRightTags[idx] };
              mutableRightTags[idx] = undefined;

              // notifyApi.error({ message: userAnswerTag.word, description: "слово написано с ошибкой", duration: 2 });
              if (lesson.checkTypos) {
                !isHintClicked &&
                  notifyApi?.error({
                    message: userAnswerTag.word,
                    description: "в слове есть ошибка",
                    duration: 10,
                  });
                return { answer: rightTag.word, ...rightTag, lemma: userAnswerTag.word, text: userAnswerTag.word, status: "typo" };
              } else {
                !isHintClicked &&
                  notifyApi?.info({
                    message: (
                      <div>
                        {userAnswerTag.word} - {[...rightTag.word].map((l, i) => (isEqualText(userAnswerTag.word[i], l) ? l : <b>{l}</b>))}
                      </div>
                    ),
                    description: "была ошибка в слове",
                    duration: 10,
                  });
                return { text: rightTag.word, answer: rightTag.word, ...rightTag, word: "", lemma: "", status: "success" };
              }
            }
          }

          return userAnswerTag;
        });
      // end userAnswerNodes

      //check order
      userAnswerNodes = userAnswerNodes.map((userAnswerTag, userAnswerIdx) => {
        if (userAnswerTag.status) return userAnswerTag;

        let tagIdx = mutableRightTags.findIndex(
          (tag, idx) =>
            isEqualEnglishWords(tag?.word, userAnswerTag.word) &&
            isEqualEnglishWords(mutableRightTags[idx - 1]?.word, userAnswerNodes[userAnswerIdx - 1]?.word) &&
            isEqualEnglishWords(mutableRightTags[idx + 1]?.word, userAnswerNodes[userAnswerIdx + 1]?.word),
        );

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex(
            (tag, idx) =>
              isEqualEnglishWords(tag?.word, userAnswerTag.word) &&
              isEqualEnglishWords(mutableRightTags[idx + 1]?.word, userAnswerNodes[userAnswerIdx + 1]?.word),
          );
        }

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex(
            (tag, idx) =>
              isEqualEnglishWords(tag?.word, userAnswerTag.word) &&
              isEqualEnglishWords(mutableRightTags[idx - 1]?.word, userAnswerNodes[userAnswerIdx - 1]?.word),
          );
        }

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex((tag) => isEqualEnglishWords(tag?.word, userAnswerTag.word));
        }

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex(
            (tag, idx) =>
              isEqualEnglishWords(tag?.lemma, userAnswerTag.lemma) &&
              isEqualEnglishWords(mutableRightTags[idx - 1]?.word, userAnswerNodes[userAnswerIdx - 1]?.word) &&
              isEqualEnglishWords(mutableRightTags[idx + 1]?.word, userAnswerNodes[userAnswerIdx + 1]?.word),
          );
        }

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex(
            (tag, idx) =>
              isEqualEnglishWords(tag?.lemma, userAnswerTag.lemma) &&
              isEqualEnglishWords(mutableRightTags[idx + 1]?.word, userAnswerNodes[userAnswerIdx + 1]?.word),
          );
        }

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex(
            (tag, idx) =>
              isEqualEnglishWords(tag?.lemma, userAnswerTag.lemma) &&
              isEqualEnglishWords(mutableRightTags[idx - 1]?.word, userAnswerNodes[userAnswerIdx - 1]?.word),
          );
        }

        if (tagIdx < 0) {
          tagIdx = mutableRightTags.findIndex((tag) => isEqualEnglishWords(tag?.lemma, userAnswerTag.lemma));
        }

        if (tagIdx >= 0) {
          const rightTag = { ...mutableRightTags[tagIdx] };
          mutableRightTags[tagIdx] = undefined;
          return { text: userAnswerTag.word, answer: rightTag.word, ...rightTag, status: "order" };
        }

        const typoElement = spellResults.find((el) => isEqualText(el.word, userAnswerTag.word));

        if (typoElement) {
          const tagIdx = mutableRightTags.findIndex((tag) =>
            typoElement.s.some((word) => isEqualEnglishWords(word, tag?.word) || isEqualEnglishWords(word, tag?.lemma)),
          );

          if (tagIdx > -1) {
            const rightTag = { ...mutableRightTags[tagIdx] };
            mutableRightTags[tagIdx] = undefined;

            return { text: userAnswerTag.word, answer: rightTag.word, ...rightTag, word: "", lemma: "", status: "typo" };
          }
        }

        return { ...userAnswerTag, status: "wrong", text: userAnswerTag.word };
      });

      // check missed words
      mutableRightTags
        .filter((el) => el)
        .forEach((rightTag) => {
          if (userAnswerNodes[rightTag.idx]?.status !== "wrong") {
            userAnswerNodes.splice(rightTag.idx, 0, {
              ...rightTag,
              status: "missed",
              text: "",
              answer: rightTag.word,
              word: "",
              lemma: "",
            });
          }
        });

      // final check
      let hasWrong = false;
      userAnswerNodes = userAnswerNodes.map((userAnswerTag, idx) => {
        if (SkipTags.includes(userAnswerTag.text)) return { ...userAnswerTag, status: "success" };

        if (userAnswerTag.status === "wrong") {
          hasWrong = true;
          return userAnswerTag;
        }

        if (userAnswerTag.status === "order" && hasWrong) {
          return { ...userAnswerTag, status: "" };
        }

        if (["missed"].includes(userAnswerTag.status || "") || !tagsWithIdx[idx]) return userAnswerTag;

        if (isEqualEnglishWords(userAnswerTag.text, tagsWithIdx[idx].word)) {
          return { ...userAnswerTag, status: "success" };
          // check lemma
        } else if (isEqualEnglishWords(userAnswerTag.lemma, tagsWithIdx[idx].lemma)) {
          return { ...userAnswerTag, status: "lemma" };
          // check article
        } else if (["a", "an", "the"].includes(userAnswerTag.text) && ["a", "an", "the"].includes(tagsWithIdx[idx].word)) {
          return { ...userAnswerTag, status: "article" };
          // check typo
        }

        return userAnswerTag;
      });

      const firstMissed = userAnswerNodes.find(
        (node, idx) =>
          node.status === "missed" && userAnswerNodes[idx - 1]?.status !== "wrong" && userAnswerNodes[idx + 1]?.status !== "wrong",
      );

      userAnswerNodes = userAnswerNodes.filter((node) => node?.status !== "missed" || node?.id === firstMissed?.id);

      if (userAnswerNodes.every((el) => el?.status === "success")) {
        return onComplete();
      }

      const skippedUserTags = taggedAnswer.filter((el) => SkipTags.includes(el.word));
      const skippedTags: any[] = tags.filter((el) => SkipTags.includes(el.word));
      skippedUserTags.forEach((userTag) => {
        const index = skippedTags.findIndex((el) => el?.word === userTag?.word);

        if (index >= 0) {
          const tag = skippedTags[index];
          skippedTags[index] = undefined;
          if (last(tags)?.word === tag.word) {
            userAnswerNodes.push({
              ...tag,
              text: tag.word,
              status: "success",
            });
          } else {
            const beforeTag = tags.find((el, idx) => tags[idx + 1]?.id === tag.id);

            if (beforeTag) {
              const positionIdx = userAnswerNodes.findIndex((node) => beforeTag.id === node.id);
              if (positionIdx >= 0) {
                userAnswerNodes.splice(positionIdx + 1, 0, {
                  ...tag,
                  text: tag.word,
                  status: "success",
                });
              }
            }
          }
        }
      });

      setStatus?.(StatusType.Error);

      if (userAnswerText && !isHintClicked) {
        API.event.save({ sentence: { id }, text: userAnswerText, type: "mistake", task: activeType, lesson: { id: lesson.id } });
      }

      // @ts-ignore
      const userAnswerWordsWithSpaces: CustomText[] = userAnswerNodes.reduce(
        (acc, tag, idx) =>
          // @ts-ignore
          [...EmptySpaceTags, ...TagsToMerge].includes(tag.text) || !idx ? [...acc, tag] : [...acc, { text: " " }, tag],
        [],
      );

      if ([TaskType.Translate, TaskType.Dictate, TaskType.Convert, TaskType.VoiceTranslate].includes(activeType)) {
        Transforms.select(editor, {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        });

        Transforms.removeNodes(editor);
        Transforms.insertNodes(editor, [{ children: userAnswerWordsWithSpaces }]);
      }

      const missed = userAnswerWordsWithSpaces.find((el) => el.status === "missed");

      if (missed && !isHintClicked) {
        if (missed.pos === "DT") {
          notifyApi?.error({
            message: (
              <>
                Пропущен артикль <b>a/an/the</b>
              </>
            ),
            duration: 3,
          });
        } else {
          notifyApi?.error({ message: "Есть пропущенные слова", duration: 2 });
        }
        return "missed";
      }

      const incorrectTag = userAnswerWordsWithSpaces.find((el) => el.status === "wrong");

      if (incorrectTag && !isHintClicked && mutableRightTags.length) {
        notifyApi?.error({ description: "Неверное слово", message: incorrectTag.text, duration: 2 });
      }

      const wrongArticle = userAnswerNodes.find((el) => el.status === "article");
      if (wrongArticle && !isHintClicked) {
        notifyApi?.warning({ description: "Неверный артикль", message: wrongArticle.text, duration: 2 });
      }

      const lemma = userAnswerWordsWithSpaces.find((el) => el.status === "lemma");

      if (lemma) {
        if (![TaskType.Translate, TaskType.Dictate, TaskType.Convert, TaskType.VoiceTranslate].includes(activeType)) {
          const maskedIdx = editor.children[0].children.findIndex((el: CustomText) => el.isMasked);
          const maskedEL = editor.children[0].children[maskedIdx];

          Transforms.setNodes(editor, { ...maskedEL, status: "lemma" }, { at: [0, maskedIdx] });
        }
        !isHintClicked &&
          notifyApi?.warning({ description: "Форма слова указана некорректно", message: lemma.text, placement: "bottom", duration: 2 });
      } else if (userAnswerWordsWithSpaces.some((el) => el.status === "wrong") && !mutableRightTags.length) {
        const extraNode = userAnswerWordsWithSpaces.find((el) => el.status === "wrong");
        notifyApi?.error({ message: extraNode?.text, description: "Лишнее слово", duration: 2 });
      }

      if (userAnswerNodes.some((el) => el.status === "order")) {
        !isHintClicked && notifyApi?.warning({ message: "Неверный порядок слов", duration: 2 });
        return "order";
      }
      return "error";
    },
    [editor, notifyApi, text, alternatives, lesson.spellerHint, lesson.checkTypos, lesson.id, tags, setStatus, activeType, onComplete, id],
  );
};
