import { LessonType, SentenceType } from "App.types";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Collapse, Popover, Typography, Tabs, Flex, Space } from "antd";
import { SoundTwoTone } from "@ant-design/icons";
import cx from "classnames";
import { findIndex, last } from "lodash";
import styles from "./StorySentence.module.scss";
import { getCompletedSlate, getSentenceAudioUrl } from "App.helpers";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { GERMAN_VOICES, SkipTags, VOICES } from "App.constants";
import { UserContext } from "App";
import Dictionary from "Components/Dictionary";
import axios from "axios";

const { Text } = Typography;

const StorySentence: FC<{
  sentence: SentenceType;
  active: boolean;
  play: Function;
  marginLeft?: boolean;
  activeWordIdx?: number;
  completedWordIdx?: number;
  onClick?: () => any;
  showText?: boolean;
  withTranslate?: boolean;
  showTranslate?: boolean;
  storyId?: string | number;
  audio?: HTMLAudioElement;
  lesson?: LessonType;
}> = ({
  storyId,
  marginLeft,
  active,
  play,
  activeWordIdx = -1,
  completedWordIdx = -1,
  onClick,
  showText = true,
  withTranslate = false,
  showTranslate = false,
  lesson,
  audio,
  sentence,
  sentence: { id, speak, voice, tags, translate, text, transcripts: storyTranscript = [], grammarLinks = [], isLeft },
}) => {
  const [open, setOpen] = useState(false);
  const [isDictOpen, setDictOpen] = useState(false);
  const [activeIdx, setActiveIdx] = useState(-1);

  const user = useContext(UserContext);

  const { data: audioData } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["audio", id, speak || text, voice || VOICES[isLeft ? 0 : 1].value, lesson?.speechRate],
    queryFn: () =>
      axios.get(getSentenceAudioUrl(id, speak || text.trim(), voice || VOICES[isLeft ? 0 : 1].value, lesson?.speechRate), {
        responseType: "blob",
      }),
  });

  const localAudio = useMemo(() => new Audio(URL.createObjectURL(new Blob([audioData?.data]))), [audioData]);

  const { data: transcripts = storyTranscript } = useQuery({
    enabled: !!text && !storyTranscript.length,
    queryKey: ["transcript", lesson?.speechRate, text, voice],
    queryFn: () =>
      !!text && !storyTranscript.length
        ? API.speech.getJson(
            text,
            lesson?.speechRate || "slow",
            voice ?? (user?.isEnglish ? VOICES[isLeft ? 0 : 1].value : GERMAN_VOICES[0]),
          )
        : undefined,
    staleTime: Infinity,
    select: (data) => data?.filter((tr) => !SkipTags.includes(tr.text)),
  });

  const onPlay = () => {
    if (localAudio) {
      localAudio.play();
    } else {
      // @ts-ignore
      play(transcripts[0].start, last(transcripts)?.end, true);
    }
  };

  const wordTags = useMemo(() => getCompletedSlate(tags, text), [tags, text]);

  useEffect(() => {
    if (audio) {
      audio.ontimeupdate = ({ target: { currentTime } }: any) => {
        // @ts-ignore
        const wordIdx = findIndex(transcripts, (el) => el.end >= currentTime);
        setActiveIdx(wordIdx > -1 ? wordIdx : -1);
      };
    }
  }, [audio, transcripts]);

  const content = showTranslate
    ? translate
    : wordTags.map((el: any, idx) => (
        <span
          key={idx}
          className={cx(styles.word, {
            [styles.word_active]: active && (el.audioIdx <= activeIdx || el.audioIdx <= activeWordIdx || el.idx <= completedWordIdx),
          })}
        >
          {el.text}
        </span>
      ));

  // const content = showTranslate
  //   ? translate
  //   : flatten(
  //       transcripts.map((tr, idx) => [
  //         { idx, text: idx === 0 || text.includes(`${transcripts[idx - 1].text}${tr.text}`) ? "" : " " },
  //         { ...tr, idx },
  //       ]),
  //     )
  //       .filter((el) => el.text)
  //       .map((el: any, idx) => (
  //         <span
  //           key={idx}
  //           className={cx(styles.word, { [styles.word_active]: el.idx === activeWordIdx || (active && el.idx <= completedWordIdx) })}
  //         >
  //           {el.text}
  //         </span>
  //       ));

  const items = grammarLinks.length
    ? [
        {
          label: "грамматика",
          children: (
            <Tabs
              size={"small"}
              items={grammarLinks.map((gram) => ({
                key: `${gram.id}`,
                label: gram.grammar?.title,
                children: <div dangerouslySetInnerHTML={{ __html: gram.grammar?.content }} />,
              }))}
            />
          ),
        },
      ]
    : [];

  return (
    <Popover
      open={open}
      onOpenChange={(visible) => setOpen(visible && !isDictOpen)}
      content={
        <div className={styles.storySent}>
          <div>{showText && <Text style={{ fontSize: 18, fontWeight: 500 }}>{showTranslate ? text : translate}</Text>}</div>
          <Space>
            <Button size={"middle"} onClick={onPlay} icon={<SoundTwoTone />} type={"text"} />

            <Button
              size={"middle"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDictOpen(true);
                setOpen(false);
              }}
            >
              словарь
            </Button>
            <Flex align={"center"}>
              <Collapse ghost accordion size={"small"} items={items} />
            </Flex>
          </Space>
        </div>
      }
      trigger={"click"}
      className={cx({ [styles.wrap]: marginLeft })}
    >
      <span
        onClick={onClick}
        className={cx(styles.storySent__text, {
          [styles.storySent__textWrap]: marginLeft,
          [styles.storySent__textActive]: open || active,
        })}
      >
        {content}
        {withTranslate && <span className={styles.storySent__translate}> {translate}</span>}
      </span>
      <Dictionary storyId={storyId} isOpen={isDictOpen} toggle={setDictOpen} sentences={sentence} />
    </Popover>
  );
};
export default StorySentence;
