import { useMemo } from "react";
import { flatten, shuffle, uniq } from "lodash";
import { SentenceType, TaskType } from "App.types";
import { STORY_TASKS } from "App.constants";

export const useAllTasks = (sentences: SentenceType[], existingTasks?: any[]) => {
  return useMemo(() => {
    if (existingTasks) return existingTasks;

    const sentWithTasks = sentences.filter((s) => s.tasks.length);

    const wordsToSelect = uniq(sentences.filter((t) => !t.linkedStoryId && t.text).map((s) => s.text));
    const translatesToSelect = uniq(sentences.filter((t) => !t.linkedStoryId && t.translate).map((s) => s.translate));

    return flatten(
      sentWithTasks.map((sent) => {
        if (sent.linkedStory) {
          const words = uniq(sent.linkedStory?.sentences.filter((t) => !t.linkedStoryId && t.text).map((s) => s.text));
          const translates = uniq(sent.linkedStory?.sentences.filter((t) => !t.linkedStoryId && t.translate).map((s) => s.translate));

          if (sent.tasks[0] === TaskType.FromStory) {
            const sentences = flatten(
              sent.linkedStory.sentences.map((s) =>
                s.tasks.map((task) => ({
                  ...s,
                  task,
                  lessonOrder: sent.order,
                  wordGroup: s.wordGroup || { id: 0, title: "from sentences", words },
                  reverseGroup: s.reverseGroup || { id: 0, title: "from sentences", words: translates },
                })),
              ),
            );

            return sent.markers.includes("random") ? shuffle(sentences) : sentences;
          }

          const items = sent.tasks.some((task) =>
            [
              TaskType.Intro,
              TaskType.StorySelect,
              TaskType.ActiveDialog,
              TaskType.Conversation,
              TaskType.Video,
              TaskType.VideoDragDrop,
              ...STORY_TASKS,
            ].includes(task),
          )
            ? sent.tasks.map((task) => {
                return [
                  TaskType.Intro,
                  TaskType.StorySelect,
                  TaskType.ActiveDialog,
                  TaskType.Conversation,
                  TaskType.Video,
                  TaskType.VideoDragDrop,
                  ...STORY_TASKS,
                ].includes(task)
                  ? [{ ...sent, task, story: sent.linkedStory, lessonOrder: sent.order }]
                  : sent.linkedStory?.sentences.map((s) => ({
                      ...s,
                      task,
                      wordGroup: s.wordGroup || { id: 0, title: "from sentences", words },
                      reverseGroup: s.reverseGroup || { id: 0, title: "from sentences", words: translates },
                      lessonOrder: sent.order,
                    }));
              })
            : sent.linkedStory?.sentences.map((s) =>
                sent.tasks.map((task) => ({
                  ...s,
                  task,
                  wordGroup: s.wordGroup || { id: 0, title: "from sentences", words },
                  reverseGroup: s.reverseGroup || { id: 0, title: "from sentences", words: translates },
                  lessonOrder: sent.order,
                })),
              );

          // @ts-ignore
          return sent.markers.includes("random") ? shuffle(flatten(items)) : flatten(items);
        } else {
          const items = sent.tasks.map((task) => ({
            ...sent,
            task,
            lessonOrder: sent.order,
            wordGroup: sent.wordGroup || { id: 0, title: "from sentences", words: wordsToSelect },
            reverseGroup: sent.reverseGroup || { id: 0, title: "from sentences", words: translatesToSelect },
          }));
          return sent.markers.includes("random") ? shuffle(items) : items;
        }
      }),
    );
  }, [existingTasks, sentences]);
};
