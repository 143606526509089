import { SentenceType, TaskType } from "App.types";
import styles from "./DialogTaskOptions.module.scss";
import React, { FC, useMemo, useState } from "react";
import { shuffle } from "lodash";
import AnimateFade from "Components/AnimateFade";
import { getAlternativeAudioUrl, isEqualText } from "App.helpers";
import { VOICES } from "App.constants";
import cx from "classnames";
import StoryDialogSentence from "Components/StoryDialogSentence";

const DialogTaskOptions: FC<{ sentence: SentenceType; onSuccess: Function; storyId?: number; audio: HTMLAudioElement }> = ({
  sentence: { isLeft, alternatives, text, voice, tasks, wordGroup },
  sentence,
  onSuccess,
  storyId,
  audio,
}) => {
  const [selectedOption, setSelectedOption] = useState<any>();
  const isCompleted = selectedOption && !selectedOption.wrong;

  const options = useMemo(
    () =>
      tasks.includes(TaskType.Select) && wordGroup?.words.length
        ? wordGroup.words.map((w) => ({ text: w, wrong: !isEqualText(text, w), audio: isEqualText(text, w) ? audio : new Audio() }))
        : shuffle([
            { text, wrong: false, audio },
            ...alternatives.map((alt) => ({
              ...alt,
              audio: new Audio(getAlternativeAudioUrl(alt.id, alt.text, voice || VOICES[isLeft ? 0 : 1].value)),
            })),
          ]).filter((opt) => opt.text.trim()),
    [tasks, wordGroup?.words, text, audio, alternatives, voice, isLeft],
  );

  return (
    <div className={styles.dialogTaskOptions}>
      {isCompleted ? (
        <StoryDialogSentence
          storyId={storyId}
          audio={selectedOption.audio}
          sentence={sentence}
          text={selectedOption.text}
          tags={selectedOption.tags}
          active={false}
          voice={voice}
        />
      ) : (
        options.map((opt, idx) => (
          <AnimateFade key={opt.text} order={idx}>
            <div
              onClick={() => {
                opt.audio?.play();
                setSelectedOption(opt);
                if (!opt.wrong) onSuccess(opt);
              }}
              className={cx(styles.option, {
                [styles.option_left]: isLeft,
                [styles.option_right]: selectedOption === opt && !opt.wrong,
                [styles.option_wrong]: selectedOption === opt && opt.wrong,
              })}
            >
              {opt.text}
            </div>
          </AnimateFade>
        ))
      )}
    </div>
  );
};

export default DialogTaskOptions;
