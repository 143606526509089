import React, { FC, useCallback, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Checkbox, Flex, notification } from "antd";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { successMessage } from "App.constants";
import styles from "./SentenceTask.module.scss";
import multiSelectStyles from "./MultiSelectTask.module.scss";
import { shuffle } from "lodash";
import { isMobile } from "react-device-detect";
import TaskPanel from "Components/TaskPanel";

const MultiSelectReverseTask: FC<SentenceTaskProps> = ({
  sentence: { id, alternatives, text, markers, options, optionStory, storyId },
  onTaskComplete,
  onNext,
  children,
  setDictOpened,
  showGrammar,
  setTry,
  lesson,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedOptions, setSelectedOptions] = useState<{ text: string; wrong: boolean }[]>([]);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const selectOptions = useMemo<{ text: string; wrong: boolean }[]>(() => {
    const items = [
      ...(optionStory?.sentences.map((s) => ({ text: s.translate, wrong: !options?.includes(s.id) })) || []),
      ...alternatives.filter((alt) => alt.translate && !alt.media).map((alt) => ({ text: alt.translate ?? "", wrong: alt.wrong ?? false })),
    ];
    return markers.includes("random") ? shuffle(items) : items;
  }, [alternatives, markers, optionStory?.sentences, options]);

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      if (showMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete(id);
    },
    [onTaskComplete, id, notifyApi],
  );

  const onOptionClick = (opt: { text: string; wrong: boolean }) => () => {
    setStatus(StatusType.Empty);

    if (selectedOptions.includes(opt)) {
      setSelectedOptions((prev) => prev.filter((el) => el !== opt));
    } else {
      setSelectedOptions((prev) => [...prev, opt]);
    }
  };

  const onCheck = useCallback(() => {
    if (selectOptions.every((opt) => (opt.wrong ? !selectedOptions.includes(opt) : selectedOptions.includes(opt)))) {
      onComplete();
    } else {
      setStatus(StatusType.Error);
      if (selectOptions.some((opt) => opt.wrong && selectedOptions.includes(opt))) {
        notifyApi.error({ message: "Есть лишний ответ!" });
      } else {
        notifyApi.error({ message: "Выбраны не все варианты!" });
      }
    }
  }, [notifyApi, onComplete, selectOptions, selectedOptions]);

  const onHintAnswer = useCallback(() => {
    selectOptions.every((opt) => {
      if (!opt.wrong && !selectedOptions.includes(opt)) {
        setTry(true);
        setSelectedOptions((prev) => [...prev, opt]);
        return false;
      }
      if (opt.wrong && selectedOptions.includes(opt)) {
        setTry(true);

        setSelectedOptions((prev) => prev.filter((el) => el !== opt));
        return false;
      }
      return true;
    });
  }, [selectOptions, selectedOptions, setTry]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        {text && (
          <div className={cx(styles.slate, styles.slate_alignCenter)}>
            <div className={styles.slate_wrapper}>
              <div className={cx(styles.textArea, styles.text)}>{text}</div>
            </div>
          </div>
        )}

        <div
          className={cx(multiSelectStyles.multiSelectTask, {
            [multiSelectStyles.multiSelectTask__completed]: status === StatusType.Completed,
          })}
        >
          <Flex gap={10} wrap={"wrap"} justify={"center"}>
            {selectOptions?.map((opt) => (
              <Checkbox
                className={cx(multiSelectStyles.option, {
                  [multiSelectStyles.option__mobile]: isMobile,
                  [multiSelectStyles.option__selected]: selectedOptions.includes(opt),
                  [multiSelectStyles.option__right]:
                    [StatusType.Error, StatusType.Completed].includes(status) && !opt.wrong && selectedOptions.includes(opt),
                  [multiSelectStyles.option__wrong]: status === StatusType.Completed && opt.wrong,
                })}
                key={opt.text}
                onChange={onOptionClick(opt)}
                checked={selectedOptions.includes(opt)}
              >
                <div>{opt.text}</div>
              </Checkbox>
            ))}
          </Flex>
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.MultiSelectReverse}
        sentId={id}
        storyId={storyId}
        onCheck={onCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        onHint={onHintAnswer}
        setDictOpened={setDictOpened}
        showGrammar={showGrammar}
      ></TaskPanel>

      {contextHolder}
    </div>
  );
};

export default MultiSelectReverseTask;
