import { SentenceType, StatusType, TaskType } from "App.types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { flatten, shuffle, uniqBy } from "lodash";
import { Flex, notification } from "antd";
import TaskPanel from "Components/TaskPanel";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import cx from "classnames";
import styles from "./MaskedDragDrop.module.scss";
import { arrayMove } from "./SentenceTask.helpers";
import { SentenceTaskProps } from "./SentenceTask.type";
import { successMessage } from "App.constants";

const MaskedDragDrop: FC<SentenceTaskProps> = ({
  setDictOpened,
  showGrammar,
  sentences = [],
  lesson,
  onTaskComplete,
  sentence,
  onNext,
}) => {
  const [status, setStatus] = useState<StatusType>();
  const [dropTags, setDropTags] = useState<{ [key: string]: { text: string; draggableId: string } | undefined }>({});
  const [tagsToSelect, setTagsToSelect] = useState<SentenceType[]>([]);
  const [wrongTags, setWrongTags] = useState<number[]>([]);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const options = useMemo(() => {
    return uniqBy(flatten(sentences.filter((s) => s.text?.trim() && (s.isCard || lesson.story.type === "card"))), "text");
  }, [lesson.story.type, sentences]);

  useEffect(() => {
    setTagsToSelect(shuffle([...options]));
  }, [options]);

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    const tag = options.find((t) => `${t.id}` === draggableId);

    if (source.droppableId === destination?.droppableId && source.index === destination.index) return;

    setWrongTags((prev) => prev.filter((id) => id !== +(destination?.droppableId || 0)));

    if (destination?.droppableId === "answers" && source.droppableId === "answers") {
      setTagsToSelect((prev) => arrayMove(prev, source.index, destination.index));

      return;
    }

    if (destination?.droppableId && tag) {
      if (source.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev.splice(source.index, 1);
          return prev;
        });

        setDropTags((prev) => ({
          ...prev,
          [destination.droppableId]: { text: tag.text, draggableId },
        }));

        if (dropTags[destination.droppableId]) {
          const sent = sentences.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
          sent && setTagsToSelect((prev) => [...prev, { ...sent }]);
        }

        return;
      }

      if (destination.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev.splice(destination.index, 0, tag);
          return prev;
        });

        if (source.droppableId !== "answers") {
          setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined }));
        }

        return;
      }

      setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined, [destination.droppableId]: { text: tag.text, draggableId } }));
      if (dropTags[destination.droppableId]) {
        const sent = sentences.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
        sent && setTagsToSelect((prev) => [...prev, { ...sent }]);
      }
    }
  };

  const onCheck = () => {
    let hasErrors = false;
    options.forEach((s) => {
      if (dropTags[`${s.id}`]?.draggableId !== `${s.id}`) {
        setStatus(StatusType.Error);
        hasErrors = true;
        setWrongTags((prev) => [...prev, s.id]);
      }
    });

    notifyApi.destroy();

    if (!hasErrors) {
      onTaskComplete(sentence.id);
      setStatus(StatusType.Completed);
      notifyApi.success(successMessage);
    } else {
      if (tagsToSelect.length) {
        notifyApi.error({ message: "Заполни все пропуски!" });
      }
    }
  };

  return (
    <div className={styles.MaskedDragDrop}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"answers"} direction={"horizontal"}>
          {(provided) => (
            <Flex
              wrap={"wrap"}
              gap={10}
              className={styles.options}
              {...provided.droppableProps}
              ref={provided.innerRef}
              // isDraggingOver={snapshot.isDraggingOver}
            >
              {tagsToSelect.map((t, index) => (
                <Draggable key={t.id} index={index} draggableId={`${t.id}`}>
                  {(draggableProvided) => (
                    <span
                      className={cx(styles.tag)}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {t.text}
                    </span>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <span />
            </Flex>
          )}
        </Droppable>

        <div className={styles.sentences}>
          {options.map((s) => (
            <Droppable
              // isDropDisabled={!!dropTags[`${el.id}`]}
              droppableId={`${s.id}`}
              key={`${s.id}`}
              direction={"horizontal"}
            >
              {(provided, snapshot) => {
                //const isRight = el.word === dropTags[`${el.id}`]?.text;

                return (
                  <div className={styles.sentence}>
                    <span
                      className={cx(styles.drop, {
                        [styles.drop__hovered]: snapshot.isDraggingOver,
                        [styles.drop__filled]: dropTags[`${s.id}`] && !snapshot.draggingFromThisWith,
                      })}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      // isDraggingOver={snapshot.isDraggingOver}
                    >
                      {dropTags[`${s.id}`] && (
                        <Draggable
                          key={dropTags[`${s.id}`]?.draggableId || ""}
                          index={0}
                          draggableId={dropTags[`${s.id}`]?.draggableId || ""}
                        >
                          {(draggableProvided, snapshot) => (
                            <span
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <span
                                className={cx(styles.tag, {
                                  [styles.tag__wrong]: !snapshot.isDragging && (wrongTags.includes(s.id) || !dropTags[`${s.id}`]),
                                })}
                              >
                                {dropTags[`${s.id}`]?.text}
                              </span>
                            </span>
                          )}
                        </Draggable>
                      )}
                      {provided.placeholder}
                    </span>
                    <span> - {s.translate}</span>
                  </div>
                );
              }}
            </Droppable>
          ))}
        </div>
      </DragDropContext>

      <TaskPanel
        sentId={sentence.id}
        lessonId={lesson.id}
        task={TaskType.MaskedDragDrop}
        storyId={sentence.linkedStoryId}
        showGrammar={showGrammar}
        onCheck={onCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
      ></TaskPanel>

      {contextHolder}
    </div>
  );
};

export default MaskedDragDrop;
