import React, { FC, memo, useMemo } from "react";
import styles from "./SentenceTask.module.scss";
import { SentenceTaskProps } from "./SentenceTask.type";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import useGrammarAudio from "Hooks/useGrammarAudio";
import TaskPanel from "Components/TaskPanel";
import { TaskType } from "App.types";

const WelcomeTask: FC<SentenceTaskProps> = ({ lesson, showGrammar, onTaskComplete, onNext, sentence: { grammar, id, storyId } }) => {
  const content = useMemo(
    () => (isMobile ? grammar?.content.replaceAll("</strong> - ", "</strong> - ") : grammar?.content),
    [grammar?.content],
  );

  useGrammarAudio(content || "");

  return (
    <div className={styles.sentenceTask}>
      <div
        className={cx(styles.grammar, { [styles.grammar__mobile]: isMobile })}
        dangerouslySetInnerHTML={{
          __html: content || "",
        }}
      />

      <TaskPanel
        sentId={id}
        lessonId={lesson.id}
        task={TaskType.Grammar}
        storyId={storyId}
        showGrammar={showGrammar}
        isCompleted
        onNext={() => onTaskComplete(id).then(() => onNext())}
      ></TaskPanel>
    </div>
  );
};

export default memo(WelcomeTask);
