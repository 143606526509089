import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useSelected } from "slate-react";
import styles from "./PageMaskedElement.module.scss";
import PageMaskedParent from "./PageMaskedParent";
import { Tooltip } from "antd";

const PageMaskedElement = (props: any) => {
  const {
    setActiveSent,
    attributes,
    children,
    element: { idx, newLine, type, translate, showTranslate },
    dontShowSelected = false,
  } = props;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const selected = useSelected();

  useEffect(() => {
    setIsTooltipOpen(selected);
  }, [selected]);

  // useEffect(() => {
  //   if (prevSelectionPath && selection && !isEqual(selection.focus.path, prevSelectionPath)) {
  //     const [node] = Editor.node(editor, prevSelectionPath);
  //     // setHint(node);
  //     console.log(node, prevSelectionPath);
  //   }
  // }, [editor, selected, selection, prevSelectionPath]);

  switch (type) {
    case "sentence":
      return (
        <span
          id={`${idx}`}
          className={cx(styles.pageMaskedElement, {
            [styles.pageMaskedElement_active]: selected && !dontShowSelected,
            [styles.pageMaskedElement_newLine]: newLine,
          })}
          {...attributes}
        >
          {showTranslate ? (
            <Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen} title={translate}>
              {children}
            </Tooltip>
          ) : (
            children
          )}
        </span>
      );

    case "parent":
      return (
        <PageMaskedParent setActiveSent={setActiveSent} {...attributes}>
          {children}
        </PageMaskedParent>
      );

    default:
      return (
        <div className={styles.div} {...attributes}>
          {children}
        </div>
      );
  }
};

export default PageMaskedElement;
