import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { ignoreTags, SkipTags } from "App.constants";
import { Button } from "antd";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { AudioOutlined, SoundFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import { flatten, last } from "lodash";
import useDeepgram from "Hooks/useDeepgram";
import TaskPanel from "Components/TaskPanel";

const initialValue = [{ children: [{ text: "" }] }];

const ReadByWordTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { text, tags, translate, id, storyId },
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  alignCenter = false,
  transcripts,
  showGrammar,
  lesson,
  noTranslate,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [activeSpeechIdx, setActiveSpeechIdx] = useState<number>(-1);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  // const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const completedSlate = useMemo(() => getCompletedSlate(tags || [], text), [text, tags]);
  const filteredRightTags = useMemo(() => completedSlate.filter((el) => el.word && !SkipTags.includes(el.word)), [completedSlate]);

  const {
    start,
    stop,
    transcript: textFromSpeech,
  } = useDeepgram({
    setStatus,
    tags: flatten(
      filteredRightTags.map((t) => [
        { ...t, isHint: true },
        { word: "", id: 0, pos: "", lemma: "" },
      ]),
    ),
  });

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [tags, editor, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        active={status === StatusType.IsRecording && props.leaf.idx > activeSpeechIdx}
        underlined={props.leaf.audioIdx === activeLeaf}
        wrong={status === "error" && props.leaf.idx === (activeLeaf ?? 0) + 1 && !ignoreTags.includes(props.leaf.text)}
        // underlined={status === "completed" || props.leaf.audioIdx <= (activeLeaf ?? -1) || props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [activeSpeechIdx, status, activeLeaf],
  );

  useEffect(() => {
    if (activeSpeechIdx >= last<any>(filteredRightTags)?.idx) {
      stop();
      setStatus(StatusType.Completed);
      onTaskComplete(sentence.id);
      setActiveSpeechIdx(-1);
    }
  }, [activeSpeechIdx, filteredRightTags, onTaskComplete, sentence.id, stop]);

  useSpeechCheck({
    status,
    textFromSpeech,
    sentence,
    completedTagIdx: activeSpeechIdx,
    setCompletedTagIdx: setActiveSpeechIdx,
    filteredRightTags,
  });

  const onRecordClick = () => {
    if (status === StatusType.IsRecording) {
      stop();
    } else {
      setActiveSpeechIdx(-1);
      setStatus(StatusType.Loading);
      start();
    }
  };

  const onSkip = useCallback(() => {
    onTaskComplete(sentence.id).then(() => onNext());
  }, [onNext, onTaskComplete, sentence.id]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                className={styles.textArea}
                readOnly
                onKeyDown={() => setStatus(StatusType.Editing)}
                renderLeaf={renderLeaf}
                //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
              />
            </Slate>
          </div>
          {(!noTranslate || status === StatusType.Completed) && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          <Button
            //style={{ visibility: status !== StatusType.Completed ? "hidden" : undefined }}
            icon={<SoundFilled />}
            onClick={() => play?.() || audio?.play()}
          />
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.ReadByWord}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        audio={audio}
      >
        <span className={styles.buttons}>
          <Button
            onClick={onRecordClick}
            className={styles.record}
            type={"primary"}
            icon={<AudioOutlined />}
            shape="circle"
            data-recording={status === "isRecording"}
            loading={status === "loading"}
          ></Button>
          <Button className={styles.btn_skip} size={"small"} type={"link"} onClick={onSkip}>
            далее
          </Button>
        </span>
      </TaskPanel>
    </div>
  );
};

export default ReadByWordTask;
